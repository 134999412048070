module.exports = {
  messages: {
    CRATE_LEBENSLAUF_FRAME_ANSCHREIBEN_BANNER:
      'Alle Lebensläufe, die Du vor 05.09.2023 erstellt hast, findest Du unter {link}',
    CRATE_LEBENSLAUF_LOGIN_BUTTON: 'Einloggen',
    CRATE_LEBENSLAUF_LOGIN_HELP: 'Hilfe',
    CRATE_LEBENSLAUF_LOGIN_LOGOUT: 'Ausloggen',
    CRATE_LEBENSLAUF_LOGIN_PROFILE: 'Dein Profil',
    CRATE_LEBENSLAUF_NAVIGATION_ADVISORY: 'Ratgeber',
    CRATE_LEBENSLAUF_NAVIGATION_CLOSE_MENU: 'Menü schließen',
    CRATE_LEBENSLAUF_NAVIGATION_CREATE_COVER_LETTER: 'Anschreiben erstellen',
    CRATE_LEBENSLAUF_NAVIGATION_CREATE_CV: 'Lebenslauf gestalten',
    CRATE_LEBENSLAUF_NAVIGATION_LOGO: 'Startseite',
    CRATE_LEBENSLAUF_NAVIGATION_OPEN_MENU: 'Menü öffnen',
    CRATE_LEBENSLAUF_NAVIGATION_STRUCTURE: 'Aufbau und Inhalt',
    CRATE_LEBENSLAUF_NAVIGATION_TEMPLATES: 'Vorlagen',
    CRATE_VITA_FOOTER_ADS: 'Werbeanzeige erstellen',
    CRATE_VITA_FOOTER_ADVERTISEMENTS: 'Werben auf XING',
    CRATE_VITA_FOOTER_ADVISOR: 'Bewerbungs-Ratgeber',
    CRATE_VITA_FOOTER_ANSCHREIBEN: 'Anschreiben-Editor',
    CRATE_VITA_FOOTER_APPS: 'Mobile & Desktop Apps',
    CRATE_VITA_FOOTER_CANCEL: 'Mitgliedschaft kündigen',
    CRATE_VITA_FOOTER_CAREER: 'Karriere',
    CRATE_VITA_FOOTER_CAREER_ADVISORY: 'Karriere-Ratgeber',
    CRATE_VITA_FOOTER_COACHES: 'Coaches + Trainer',
    CRATE_VITA_FOOTER_COMPANIES: 'Unternehmen',
    CRATE_VITA_FOOTER_COMPANY: 'Unternehmen',
    CRATE_VITA_FOOTER_COPYRIGHTS: '© New Work SE | Alle Rechte vorbehalten',
    CRATE_VITA_FOOTER_DATA: 'Datenschutzerklärung',
    CRATE_VITA_FOOTER_DEVBLOG: 'Devblog',
    CRATE_VITA_FOOTER_EMPLOYEES: 'Personalgewinnung mit XING',
    CRATE_VITA_FOOTER_FREELANCER: 'Freelancer verwalten',
    CRATE_VITA_FOOTER_HELP: 'Hilfe & Kontakt',
    CRATE_VITA_FOOTER_INTERNSHIPS: 'Praktikums-Guide',
    CRATE_VITA_FOOTER_INVESTOR_RELATIONS: 'Investor Relations',
    CRATE_VITA_FOOTER_JOBS: 'Jobs',
    CRATE_VITA_FOOTER_LEBENSLAUF: 'Lebenslauf-Editor',
    CRATE_VITA_FOOTER_LEGAL: 'Impressum',
    CRATE_VITA_FOOTER_MAIN: 'Hauptbereiche',
    CRATE_VITA_FOOTER_MEMBERSHIPS: 'Mitgliedschaften',
    CRATE_VITA_FOOTER_NEWS: 'XING News',
    CRATE_VITA_FOOTER_NEWWORK: 'New Work SE',
    CRATE_VITA_FOOTER_NWX: 'NWX – Neues zur Zukunft der Arbeit',
    CRATE_VITA_FOOTER_PREMIUM: 'Premium',
    CRATE_VITA_FOOTER_PRESS: 'Presse',
    CRATE_VITA_FOOTER_PRIVACY: 'Datenschutz bei XING',
    CRATE_VITA_FOOTER_PROJOBS: 'ProJobs',
    CRATE_VITA_FOOTER_RATINGS: 'Arbeitgeber-Bewertungen',
    CRATE_VITA_FOOTER_RESOURCES: 'Ressourcen',
    CRATE_VITA_FOOTER_SERVICES: 'Services',
    CRATE_VITA_FOOTER_TERMS: 'AGB',
    CRATE_VITA_FOOTER_USERS: 'Mitgliederverzeichnis',
  },
};
